<template>
  <div class="floorplan">
    <div v-if="false && floorplan" class="floorplan__left-menu">
      <h5> Companies </h5>
      <li
        v-for="location in floorplan.locations"
        :key="location.id"
        @click="zoomToLocation(location)"
        style="display: block"
        class="floorplan__left-menu-item"
        :class="{ 'floorplan__left-menu-item--selected': selected === location }"
      >
        {{ location.name }}
      </li>
    </div>
    <div
      class="absolute inset-0"
      ref="container"
      :class="{ 'cursor-not-allowed': lockSelected }"
    >
      <svg
        v-if="floorplan"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        :viewBox="`${viewBox[0]} ${viewBox[1]} ${viewBox[2]} ${viewBox[3]}`"
        ref="svg"
      >
        <image
          x="0"
          y="0"
          :width="floorplan.width"
          :href="floorplan.background_url"
        />
        <defs>
          <pattern id="smallGrid" width="100" height="100" patternUnits="userSpaceOnUse">
            <rect
              width="100"
              height="100"
              stroke="#e2e8f0"
              stroke-width="1"
              fill="none"
            />
            <circle cx="0" cy="0" r="5" fill="#94a3b8"/>
            <circle cx="100" cy="0" r="5" fill="#94a3b8"/>
            <circle cx="0" cy="100" r="5" fill="#94a3b8"/>
            <circle cx="100" cy="100" r="5" fill="#94a3b8"/>
          </pattern>
          <pattern id="grid" width="500" height="500" patternUnits="userSpaceOnUse">
          <rect
            width="500"
            height="500"
            fill="url(#smallGrid)"
            stroke="#cbd5e1"
            stroke-width="2"
          />
          </pattern>
        </defs>
        <g v-if="showGrid">
          <rect
            x="0"
            y="0"
            :width="floorplan.width"
            :height="floorplan.depth"
            fill="url(#grid)"
          ></rect>
          <text
            v-for="y in Math.floor(floorplan.depth / floorplan.rounding_factor)"
            :key="y"
            :y="y * 100"
            x="-20"
            font-size="50"
            dominant-baseline="middle"
            text-anchor="end"
            fill="#64748b"
          >
            {{ y }}
          </text>
          <text
            v-for="x in Math.floor(floorplan.width / floorplan.rounding_factor)"
            :key="x"
            y="0"
            :x="x*100"
            font-size="50"
            :transform="`translate(${100 * x},-20) rotate(-45) translate(${-100 * x},0)`"
            dominant-baseline="middle"
            text-anchor="start"
            fill="#64748b"
          >
            {{ x }}
          </text>
        </g>
        <g>
          <FeFloorplanStand
            v-show="location.id !== selected?.id"
            v-for="location in floorplan.locations"
            :key="location.id"
            :location="location"
            @click="selectLocation(location)"
          />
          <g v-if="selected">
            <FeFloorplanStand
              v-if="selected"
              ref="selectedEl"
              :location="selected"
              fillColour="#B0E0A8"
              :draggable="lockSelected"

            />
            <template v-if="isEditingLocation">
              <rect
                :x="selected.x + (selected.width / 2) - 50"
                :y="selected.y - 50"
                :width="100"
                :height="100"
                fill="#ffffff"
                stroke="#000000"
                stroke-width="5"
                ref="topHandle"
                class="cursor-row-resize"
              />
              <rect
                :x="selected.x + selected.width - 50"
                :y="selected.y + (selected.depth / 2) - 50"
                :width="100"
                :height="100"
                fill="#ffffff"
                stroke="#000000"
                stroke-width="5"
                ref="rightHandle"
                class="cursor-col-resize"
              />
              <rect
                :x="selected.x + (selected.width / 2) - 50"
                :y="selected.y + selected.depth - 50"
                :width="100"
                :height="100"
                fill="#ffffff"
                stroke="#000000"
                stroke-width="5"
                ref="bottomHandle"
                class="cursor-row-resize"
              />
              <rect
                :x="selected.x - 50"
                :y="selected.y + (selected.depth / 2) - 50"
                :width="100"
                :height="100"
                fill="#ffffff"
                stroke="#000000"
                stroke-width="5"
                ref="leftHandle"
                class="cursor-col-resize"
              />
            </template>
          </g>
        </g>
        <g v-if="showPaths">
          <g
            v-for="path in floorplan.paths"
            :key="path.id"
          >
            <line
              :x1="path.x1"
              :y1="path.y1"
              :x2="path.x2"
              :y2="path.y2"
              stroke="red"
              stroke-width="16"
              @click="$emit('pathClicked', path)"
            />
           <circle
             :cx="path.x1"
             :cy="path.y1"
             r="24"
             fill="red"
           />
           <circle
             :cx="path.x2"
             :cy="path.y2"
             r="24"
             fill="red"
           />
          </g>

          <g
            v-for="(path,i) in activePath"
            :key="path.id"
          >
            <line
              v-if="i > 0"
              :x1="activePath[i-1].x"
              :y1="activePath[i-1].y"
              :x2="path.x"
              :y2="path.y"
              stroke="green"
              stroke-width="16"
              @click="$emit('pathClicked', path)"
            />
           <circle
             :cx="path.x"
             :cy="path.y"
             r="24"
             fill="green"
           />
           <circle
             :cx="path.x2"
             :cy="path.y2"
             r="24"
             fill="green"
           />
          </g>
        </g>
      </svg>
      <div class="floorplan__coords bg-rock-200/50" v-if="svgX !== null && svgY !== null">
        <i class="fas fa-compass me-1"></i>
        {{ (svgX / floorplan.rounding_factor).toFixed(2) }},
        {{ (svgY / floorplan.rounding_factor).toFixed(2) }}
      </div>
    </div>

  </div>

</template>

<script setup>
import {
  ref, watch, toRefs, onMounted, nextTick,
} from 'vue';
import { useEventListener, useElementSize, useKeyModifier } from '@vueuse/core';
import FeFloorplanStand from './FeFloorplanStand.vue';

const props = defineProps({
  floorplan: Object,
  showGrid: Boolean,
  showPaths: Boolean,
  width: Number,
  height: Number,
  lockSelected: Boolean,
  activePath: {
    type: Array,
    default: () => [],
  },
  selected: Object,
  isEditingLocation: Boolean,
});

const emit = defineEmits([
  'locationSelected',
  'locationDblClicked',
  'pathClicked',
  'hover',
  'locationMove',
  'locationMoved',
  'svgClicked',
  'locationResize',
  'locationResized',
]);

const { floorplan } = toRefs(props);

const container = ref(null);

const { width: containerWidth, height: containerHeight } = useElementSize(container);

const viewBox = ref([0, 0, 0, 0]);

onMounted(() => {
  // Scale and center the image.
  const multiplier = (containerWidth.value / containerHeight.value) * (floorplan.value.depth / floorplan.value.width);

  const viewportHeight = (1.5 * floorplan.value.depth) / Math.min(1, multiplier);
  const viewportWidth = 1.5 * floorplan.value.width * Math.max(1, multiplier);
  const startX = (viewportWidth - floorplan.value.width) / 2;
  const startY = (viewportHeight - floorplan.value.depth) / 2;
  viewBox.value = [-1 * startX, -1 * startY, viewportWidth, viewportHeight];
});

watch(containerWidth, (newVal, oldVal) => {
  if (oldVal) viewBox.value[2] *= newVal / oldVal;
});

watch(containerHeight, (newVal, oldVal) => {
  if (oldVal) viewBox.value[3] *= newVal / oldVal;
});

const svg = ref(null);

function transitionViewBox(
  targetX,
  targetY,
  targetWidth,
  targetHeight,
  duration = 1000,
) {
  const startX = viewBox.value[0];
  const startY = viewBox.value[1];
  const startWidth = viewBox.value[2];
  const startHeight = viewBox.value[3];

  const startTime = performance.now();

  function animate(time) {
    let progress = (time - startTime) / duration;

    progress = Math.min(1, progress); // Cap at 1

    // Simple easing function for a smooth effect (ease-in-out)
    const easeFactor = 0.5 - Math.cos(progress * Math.PI) / 2;

    // Interpolate each viewBox parameter
    viewBox.value = [
      startX + (targetX - startX) * easeFactor,
      startY + (targetY - startY) * easeFactor,
      startWidth + (targetWidth - startWidth) * easeFactor,
      startHeight + (targetHeight - startHeight) * easeFactor,
    ];

    if (progress < 1) {
      requestAnimationFrame(animate);
    }
  }

  // Start the animation
  requestAnimationFrame(animate);
}

const selectedEl = ref(null);
let isPanning = false;
let isDragging = false;
let isResizing = false;
const topHandle = ref(null);
const rightHandle = ref(null);
const bottomHandle = ref(null);
const leftHandle = ref(null);

function selectLocation(location) {
  if (props.lockSelected) {
    return;
  }

  if (props.selected?.id === location.id) {
    return;
  }

  emit('locationSelected', location);
}

function moveSelected({ movementX, movementY }) {
  const ctm = svg.value.getScreenCTM();

  const movement = new DOMMatrix([0, 0, movementX, movementY, 0, 0]).multiply(ctm.inverse());

  if (isDragging) {
    const newX = props.selected.x + movement.c - movement.a;
    const newY = props.selected.y + movement.d - movement.b;

    if (newX !== props.selected.x || newY !== props.selected.y) {
      emit('locationMove', {
        x: newX,
        y: newY,
      });
    }
  }

  // selected.value.x += movement.c - movement.a;
  // selected.value.y += movement.d - movement.b;
  // selected.value.centre_x += movement.c - movement.a;
  // selected.value.centre_y += movement.d - movement.b;
}

function zoomToLocation(location) {
  const newWidth = 2000;
  const newHeight = 1000;

  emit('locationSelected', location);

  transitionViewBox(
    location.centre_x - (newWidth / 2),
    location.centre_y - (newHeight / 2),
    newWidth,
    newHeight,
  );
}

function screenToSvg(x, y) {
  const ctm = svg.value.getScreenCTM();

  return DOMPointReadOnly.fromPoint({ x, y })
    .matrixTransform(ctm.inverse());
}

function zoomToPoint(scaleFactor, x, y) {
  const svgPoint = screenToSvg(x, y);

  viewBox.value = [
    (viewBox.value[0] - svgPoint.x) * scaleFactor + svgPoint.x,
    (viewBox.value[1] - svgPoint.y) * scaleFactor + svgPoint.y,
    Math.max(viewBox.value[2] * scaleFactor, 1),
    Math.max(viewBox.value[3] * scaleFactor, 1),
  ];
}

function pan(x, y) {
  const ctm = svg.value.getScreenCTM();

  const panned = new DOMMatrix([0, 0, x, y, 0, 0]).multiply(ctm.inverse());

  viewBox.value[0] += panned.c - panned.a;
  viewBox.value[1] += panned.d - panned.b;
}

useEventListener(selectedEl, 'dblclick', () => {
  if (!props.isEditingLocation) {
    emit('locationDblClicked', props.selected);
  }
});

useEventListener(svg, 'wheel', (event) => {
  zoomToPoint(1 + 0.01 * event.deltaY, event.clientX, event.clientY);
  event.preventDefault();
});

const cmdPressed = useKeyModifier('Meta');

useEventListener(topHandle, 'mousedown', () => {
  isResizing = 'up';
});

useEventListener(rightHandle, 'mousedown', () => {
  isResizing = 'right';
});

useEventListener(bottomHandle, 'mousedown', () => {
  isResizing = 'down';
});

useEventListener(leftHandle, 'mousedown', () => {
  isResizing = 'left';
});

function resize(event, emitEvent) {
  const ctm = svg.value.getScreenCTM();
  const movement = new DOMMatrix([0, 0, event.movementX, event.movementY, 0, 0]).multiply(ctm.inverse());

  const newDimensions = {
    x: props.selected.x,
    y: props.selected.y,
    width: props.selected.width,
    depth: props.selected.depth,
  };

  if (isResizing === 'up') {
    const y = props.selected.y - (movement.b - movement.d);
    const depth = props.selected.depth + (movement.b - movement.d);

    if (depth > 1) {
      newDimensions.y = y;
      newDimensions.depth = depth;
      emit(emitEvent, newDimensions);
    }
    return;
  }

  if (isResizing === 'right') {
    const width = props.selected.width + movement.c - movement.a;

    if (width > 1) {
      newDimensions.width = width;
      emit(emitEvent, newDimensions);
    }
    return;
  }

  if (isResizing === 'down') {
    const depth = props.selected.depth + movement.d - movement.b;

    if (depth > 1) {
      newDimensions.depth = depth;
      emit(emitEvent, newDimensions);
    }
    return;
  }

  if (isResizing === 'left') {
    const x = props.selected.x - (movement.a - movement.c);
    const width = props.selected.width + (movement.a - movement.c);

    if (width > 1) {
      newDimensions.x = x;
      newDimensions.width = width;
      emit(emitEvent, newDimensions);
    }
  }
}

// register mouse up events for each resize handle
useEventListener(window, 'mouseup', (event) => {
  if (isResizing) {
    event.preventDefault();
    resize(event, 'locationResized');
    isResizing = false;
  }
});

useEventListener(svg, 'mousedown', () => {
});

useEventListener(svg, 'mousemove', (event) => {
  event.preventDefault();

  if (event.buttons !== 0) {
    if (isResizing) {
      resize(event, 'locationResize');
      return;
    }

    // event.buttons is a number indicating which button(s) are pressed
    if (!isDragging) {
      isPanning = true;
      pan(-1 * event.movementX, -1 * event.movementY);
      return;
    }

    if (isDragging && props.lockSelected) {
      moveSelected(event);
    }
  }
});

useEventListener(window, 'mouseup', (event) => {
  const { x, y } = (screenToSvg(event.clientX, event.clientY));
  emit('svgClicked', {
    x,
    y,
    modifiers: {
      cmd: cmdPressed.value,
      isPanning,
      isDragging,
    },
    event,
  });

  isPanning = false;

  if (isDragging) {
    emit('locationMoved', {
      x: props.selected.x,
      y: props.selected.y,
    });
  }
  isDragging = false;
});

useEventListener(selectedEl, 'mousedown', () => {
  if (props.isEditingLocation) {
    isDragging = true;
  }
});

watch(
  floorplan,
  () => floorplan.value.locations.sort((a, b) => {
    if (a.name > b.name) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    return 0;
  }),
);

const svgX = ref(null);
const svgY = ref(null);

useEventListener(svg, 'mousemove', ({ clientX, clientY }) => {
  const { x, y } = (screenToSvg(clientX, clientY));
  svgX.value = x;
  svgY.value = y;
});

async function download() {
  await nextTick();

  const blob = new Blob(
    [svg.value.outerHTML],
    { type: 'text/svg' },
  );
  const filename = 'floorplan.svg';

  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const elem = window.document.createElement('a');
    elem.href = window.URL.createObjectURL(blob);
    elem.download = filename;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
  }
}

defineExpose({
  download,
  zoomToLocation,
});
</script>

<style>

.floorplan {
  position: relative;
  background-color: #ffffff;
  border: 1px solid var(--gray-2);
  display: grid;
  grid-template-columns: auto;
  width:100%;
  height: 100%;
  max-width:100%;
}

.floorplan__left-menu {
  background: #eee;
  border-right: 4px;
  padding: 1rem;
}

.floorplan__left-menu-item--selected {
  text-decoration: underline;
}

.floorplan__coords {
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 1rem;
  padding: 0.5rem;
  min-width: 10rem;
  text-align: center;
  font-size: 12px;
  z-index: 100;
}

</style>
